import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Venta-Trans Europe - Freight Forwarding Company
			</title>
			<meta name={"description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:title"} content={"Venta-Trans Europe - Freight Forwarding Company"} />
			<meta property={"og:description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/og_img.jpg?v=2021-04-21T14:38:42.609Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/favicon-32x32.png?v=2021-04-22T09:26:54.780Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section
			position="sticky"
			height="70px"
			lg-height="60px"
			font="--headline1"
			quarkly-title="SectionHeader"
			background="rgba(255, 255, 255, 0.95) linear-gradient(0deg,rgba(255, 255, 255, 0.2) 0.5%,#ffffff 100%)"
			top={0}
			z-index="2"
			lg-padding="10px 0 10px 0"
		>
			<Override slot="SectionContent" lg-height="60px" />
			<Box
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="row"
				md-display="flex"
				lg-padding="0 0 0 0"
				display="flex"
				padding="12px 0"
			>
				<Link
					href="#top"
					padding="0px 10px 0px 10px"
					md-width="50%"
					sm-width="60%"
					target="_self"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/logo_web.svg?v=2021-04-20T09:43:08.919Z) 0% 50% /auto no-repeat scroll padding-box"
					width="30%"
					height="80px"
					md-padding="0px 10px 0px 0"
					md-display="inline-block"
				/>
				<Components.BurgerMenu color="--text" font="--menu" lg-font="--menuMob" md-display="flex">
					<Override slot="menu" font="--menu" color="--text" md-justify-content="start">
						<Override slot="link-certification" target="_self" href="#certification" />
						<Override slot="item-active" font="--menu" />
						<Override slot="item-operations" font="--menu" color="--text" />
						<Override slot="link-operations" font="--menu" target="_self" href="#operations" />
						<Override slot="link-contacts" target="_self" href="#contacts" />
						<Override slot="link-insurance" target="_self" href="#insurance" />
						<Override slot="link-modality" target="_self" href="#modality" />
						<Override
							slot="link"
							font="--menu"
							color="--text"
							letter-spacing="normal"
							lg-font="--menuMob"
							md-font="--leadMob"
						/>
						<Override
							slot="item"
							text-transform="initial"
							font="--menu"
							color="--text"
							lg-font="--menuMob"
							lg-min-width="45px"
							min-width="65px"
						/>
						<Override slot="link-about-us" target="_self" href="#about" />
					</Override>
					<Override slot="menu-open" md-justify-content="start" md-padding="80px 6px 6px 6px" />
				</Components.BurgerMenu>
				{" "}
			</Box>
		</Section>
		<Section
			min-height="600px"
			sm-min-height="auto"
			background="#fff"
			display="flex"
			flex-direction="column"
			padding="60px 0"
			sm-padding="40px 0"
		>
			<Stack margin="10px 0 -16px 0px" color="--grey" font="--base">
				<StackItem width="100%" md-width="100%" display="flex">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--dark" as="h1" font="--headline1" md-font="--headline2">
						404 - PAGE NOT FOUND
					</Text>
					<Link
						target="_self"
						sm-width="60%"
						href="/index"
						background="rgba(0, 0, 0, 0)"
						md-padding="0px 10px 0px 0"
						md-display="inline-block"
						md-width="50%"
					>
						GO TO HOMEPAGE
					</Link>
				</StackItem>
			</Stack>
		</Section>
		<Section background="--color-logo" quarkly-title="Footer">
			<Override slot="SectionContent" sm-margin="0px auto 0px auto" />
			<Box sm-font="--base" justify-content="center" display="flex">
				<Text
					margin="16px 0px 16px 0"
					color="--fonText"
					sm-font="--text11Mob"
					sm-padding="0px 0px 0px 0px"
					padding="0px 10px 0px 0px"
					font="--lead"
					display="block"
				>
					© 2021 Venta-Trans Europe.
				</Text>
				<Text
					font="--lead"
					display="block"
					margin="16px 0px 16px 0"
					color="--fonText"
					sm-font="--text11Mob"
					sm-padding="0px 0px 0px 0"
				>
					All Rights Reserved.
				</Text>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfBody"} rawKey={"608142d6ab4dd832c0755f1a"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script src={"https://www.googletagmanager.com/gtag/js?id=G-118BTS02HM"} async={true} place={"endOfHead"} rawKey={"6082f9b76cd311d618e2da67"} />
			<script place={"endOfHead"} rawKey={"6082f9d0a29b8aa4421cb038"}>
				{"window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\ngtag('config', 'G-118BTS02HM');"}
			</script>
			<script src={"https://fast.wistia.com/embed/medias/ivn4prep36.jsonp"} async={true} place={"endOfHead"} rawKey={"6083012406cc56de18db89d6"} />
			<script async={true} src={"https://fast.wistia.com/assets/external/E-v1.js"} place={"endOfHead"} rawKey={"608301398ecb4f30a2c73278"} />
		</RawHtml>
	</Theme>;
});